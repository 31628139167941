import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ScaleLoader } from 'react-spinners';

import './index.scss';

const Spinner = ({ light, className }) => (
  <span className={classNames('Spinner', className)}>
    <ScaleLoader color={light ? 'white' : 'black'} />
  </span>
);

Spinner.propTypes = {
  light: PropTypes.bool,
  className: PropTypes.string,
};

Spinner.defaultProps = {
  light: false,
  className: null,
};

export default Spinner;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import {
  compose as Rcompose,
  filter,
  map,
  not,
  path,
  prop,
  reduce,
} from 'ramda';
import { compose, withProps } from 'recompose';
import { createSelector, createStructuredSelector } from 'reselect';

import { productPath } from '/src/utils/paths';
import { selectors } from '/src/store/cart';
import { currencyToString } from '/src/utils/currency';
import { newlinesToDivs } from '/src/utils/string';

const ProductCard = ({ product: { name, description }, image, priceRange }) => (
  <div className="ProductCart">
    {image && (
      <div className="ProductCart__image">
        <img src={image} alt="" className="img-responsive" />
      </div>
    )}
    <div className="ProductCart__details">
      <h3 className="ProductCart__title heading4">
        <Link to={productPath(name)}>{name}</Link>
      </h3>
      {description && (
        <p className="ProductCart__description">
          {newlinesToDivs(description)}
        </p>
      )}
      <p>{priceRange}</p>
    </div>
  </div>
);

ProductCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    fields: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    skus: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.string.isRequired,
          price: PropTypes.number.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

const skusSelector = createSelector(
  path(['product', 'skus']),
  filter(Rcompose(not, selectors.isShippingSku))
);

const imageSelector = createSelector(
  skusSelector,
  skus => prop('image', skus.find(sku => !!sku.image) || {})
);

const pricesSelector = createSelector(
  skusSelector,
  map(prop('price'))
);

const minPriceSelector = createSelector(
  pricesSelector,
  reduce(Math.min, Infinity)
);

const maxPriceSelector = createSelector(
  pricesSelector,
  reduce(Math.max, -Infinity)
);

const priceRangeSelector = createSelector(
  minPriceSelector,
  maxPriceSelector,
  (min, max) => {
    return min !== max
      ? `${currencyToString(min)} - ${currencyToString(max)}`
      : currencyToString(min);
  }
);

const propSelectors = createStructuredSelector({
  image: imageSelector,
  priceRange: priceRangeSelector,
});

export default compose(withProps(propSelectors))(ProductCard);

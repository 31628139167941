import React from 'react';

import { Row, Col } from '/src/components/atoms/GridRow';
import ProductCard from '/src/components/molecules/ProductCard';

const ProductGrid = ({ products }) => (
  <Row center>
    {products.map(product => (
      <Col key={product.id} width={4}>
        <ProductCard product={product} />
      </Col>
    ))}
  </Row>
);

export default ProductGrid;

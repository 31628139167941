import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Row, Col } from '/src/components/atoms/GridRow';
import Spinner from '/src/components/atoms/Spinner';
import Layout from '/src/components/layout';
import SEO from '/src/components/atoms/Seo';
import ContentSection from '/src/components/atoms/ContentSection';
import ProductGrid from '/src/components/organisms/ProductGrid';

const productsQuery = gql`
  query AllProducts {
    stripeProducts(filter: { active: true }) {
      id
      name
      description
      skus {
        attributes {
          name
        }
        image
        price
      }
    }
  }
`;

const ShopPage = () => {
  const { loading, error, data } = useQuery(productsQuery);

  return (
    <Layout hideHeader>
      <SEO title="Shop" />
      <ContentSection>
        <h1 className="heading3">Shop</h1>
      </ContentSection>
      <ContentSection light>
        <Row center vertGutter>
          <Col width={8}>
            <h2 className="heading3">All Merch</h2>
            {!error ? (
              !loading ? (
                <ProductGrid products={data.stripeProducts} />
              ) : (
                <Spinner />
              )
            ) : (
              <p>Error loading items</p>
            )}
          </Col>
        </Row>
      </ContentSection>
    </Layout>
  );
};

export default ShopPage;
